html,
body {
    background-color: #f0f2f5;
    margin: 0;
}

header.ant-layout-header {
    display: flex;
}

main.maincontent.ant-layout-content {
    padding: 0px 50px;
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.site-layout-content {
    background-color: white;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
